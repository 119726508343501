import React, { Component } from "react";
import { Link } from "react-router-dom";

class ContactInfoCard extends Component {
  render() {
    return (
      <>
        <div className="bg-primary text-white contact-info-bx">
          <div className="heading-bx left mb-4">
            <h3 className="m-b10 title-head">
              Contact <span> Info</span>
            </h3>
            <p className="m-b0">
              Welcome to our Website. We are glad to have you around.
            </p>
          </div>
          <div className="widget widget_getintuch">
            <ul>
              <li>
                <i className="ti-location-pin"></i>Level 4, 310 King Street,
                Melbourne, VIC 3000, Australia
              </li>
              <li>
                <i className="ti-mobile"></i>(+61) 3 7002 1544
              </li>
              <li>
                <i className="ti-email"></i>info@sem.edu.au
              </li>
            </ul>
          </div>
          <h5 className="m-t0 m-b20">Follow Us</h5>
          <ul className="list-inline contact-social-bx m-b0">
            <li>
              <a
                href="https://www.facebook.com/SMART-English-Melbourne-106813281816034"
                className="btn outline radius-xl"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/smart_english_melbourne/"
                className="btn outline radius-xl"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default ContactInfoCard;

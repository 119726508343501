import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

// Images
import aboutPic1 from "../../../images/about/pic1.jpg";

class OurStory1 extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <div className="section-area bg-gray section-sp1 our-story">
          <div className="container">
            <div className="row align-items-center d-flex">
              <div className="col-lg-5 col-md-12 m-b30">
                <div className="heading-bx left mb-3">
                  <h2 className="title-head">
                    ABOUT SMART <span>ENGLISH</span>
                  </h2>
                  <p>
                    Smart English has the tools to transform your dream of
                    MASTERING English into reality.
                  </p>
                </div>
                <p className="m-b30">
                  “To have a second language is to possess a second soul. ”
                </p>
                <Link to="/about-us" className="btn">
                  Read More
                </Link>
              </div>
              <div className="col-lg-7 col-md-12 heading-bx p-lr">
                <div className="video-bx">
                  <img src={aboutPic1} alt="" />
                  {/* <Link onClick={this.openModal} to="#" className="popup-youtube video"><i className="fa fa-play"></i></Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="x_sJzVe9P_8"
          onClose={() => this.setState({ isOpen: false })}
        />
      </>
    );
  }
}

export default OurStory1;

import React, { Component } from "react";
import { Link } from "react-router-dom";

class Counter1 extends Component {
  render() {
    return (
      <>
        <div
          className="section-area section-sp1 bg-fix ovbl-dark text-white"
          style={{
            backgroundImage:
              "url(https://sem.ebizlab.co/wp-content/uploads/2020/10/Smart-English-Melbourne.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center text-white">
                <h2>Become a global citizen with multiple Language</h2>
                <p>
                  Personalised study plans to achieve your goals within your
                  time expectations Qualified teachers with extensive experience
                  in Australia and overseas Multi-language student support staff{" "}
                </p>
                <div className="btn">
                  <Link to="/contact-us" className="btn">
                    Get Started
                  </Link>
                </div>
                {/* <button className="btn" type="submit">
                  Get Started
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Counter1;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";

// Elements
import BlogAside from "../elements/blog-aside2";

// Images
import bannerImg from "../../images/banner/banner2.jpg";

const ServicesDetails = (props) => {
  let data = props.location.state;
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">Our Services</h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Our Services</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="section-area section-sp2">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-xl-8">
                  <div className="recent-news blog-lg" id="medical">
                    <div className="action-box blog-lg">
                      <h3>Medical Insurance - OSHC</h3>
                      <img
                        src="http://www.smeag.com.au/student-services/images/1-SERVICES.jpg"
                        alt="dccd"
                      />
                    </div>
                    <div className="info-bx">
                      <h3 className="post-title">
                        Seven Reasons You Should Fall In Love With Education.
                      </h3>
                      <p>
                        Overseas Student Health Cover (OSHC) is insurance to
                        assist international students meet the costs of medical
                        and hospital care that they may need while in Australia.
                        OSHC will also pay limited benefits for pharmaceuticals
                        and ambulance services.
                      </p>
                      <p>
                        All international students are required to have Overseas
                        Student Health Cover (OSHC) throughout the entire length
                        of their visa. If you want SMEAG English to arrange
                        cover for you, we will contact our preferred OHSC
                        partner BUPA and will make it happen. Please Click here
                        for more information on the covers and their costs.
                      </p>
                      <p>
                        The Overseas Student Health Cover (OSHC) with BUPA will:
                      </p>
                      <ul>
                        <li>Cover hospital expenses,</li>
                        <li>GP or specialist costs</li>
                        <li>Ambulance</li>
                        <li>
                          Benefits for some of your prescription medicines
                        </li>
                        <li>Special offers and discounts</li>
                      </ul>
                      <p>
                        BUPA will not pay for the cost of dental treatment,
                        physiotherapy or glasses. Waiting periods apply for the
                        cover of medical conditions which existed prior to your
                        arrival in Australia.
                      </p>

                      <h4>Q&A</h4>
                      <h6>Q. What is OSHC?</h6>
                      <p>
                        Yes, it is. There are companies that provide the minimum
                        wage and salary.
                      </p>
                      <h6>Q. Why do I need OSHC?</h6>
                      <p>
                        As an overseas student, it is a requirement of your
                        overseas student visa that you have OSHC when you apply
                        for your visa and then keep it for the entire duration
                        of your stay in Australia under the student visa.
                      </p>
                      <h6>
                        Q. I am a working holiday visa holder. Do I have to have
                        OSHC?
                      </h6>
                      <p>
                        No. OSHC is only for international student visa holders.
                        If you are holding a working holiday visa or visitor
                        visa, you can apply for Visitor health cover.
                      </p>
                      <h6>Q. What if I have a family or dependants?</h6>
                      <p>
                        They will not be covered under your singles policy. You
                        should take out an OSHC family policy to cover your
                        spouse or de-facto partner or any child or step child
                        who is unmarried and under the age of 18. We also have
                        Extras cover for families.
                      </p>
                      <h4>Related links</h4>
                      <ul>
                        <li>
                          CONTENTS More information here ->{" "}
                          <a href=" Health government AU">Health Government</a>
                        </li>
                        <li>
                          BUPA Private insurance company ->{" "}
                          <a href=" https://www.bupa.com.au">BUPA</a>
                        </li>
                        <li>
                          CONTENTS More information here ->{" "}
                          <a href="https://allianzassistancehealth.com.au/en/student-visa-oshc/">
                            Allicance Health
                          </a>
                        </li>
                        <li>
                          CONTENTS More information here ->{" "}
                          <a href="https://www.medibank.com.au/overseas-health-insurance/oshc/">
                            Media Bank
                          </a>
                        </li>
                      </ul>
                      <div className="ttr-divider bg-gray">
                        <i className="icon-dot c-square"></i>
                      </div>
                    </div>
                  </div>
                  <div className="recent-news blog-lg" id="accomodation">
                    <div className="action-box blog-lg">
                      <h3>Student Accommodation & Airport Pickup</h3>
                      <img
                        src="http://www.smeag.com.au/student-services/images/2-SERVICES.jpg"
                        alt="dccd"
                      />
                    </div>
                    <div className="info-bx">
                      <p>
                        SMART English provides several accommodation options
                        according to your needs and preferences. To fully enjoy
                        the international experience, you can choose to stay at
                        a homestay family or at a student residence. All our
                        accommodation services are the result of a long
                        partnership with our main providers, so rest assured
                        that we regularly check that the quality and safety
                        standards are constantly met.
                      </p>
                      <h3>Homestay</h3>
                      <p>
                        Living the Aussie life with a homestay family is one of
                        those memories that you will never forget. Staying with
                        a homestay family encourages a cultural and linguistic
                        exchange. You will have the chance to speak English at
                        all times and learn more about Australia and its
                        multiculturalism while enjoying the food, lifestyle and
                        habits of an Australian family.
                      </p>
                      <img
                        src="http://www.smeag.com.au/student-services/images/3-SERVICES.jpg"
                        alt="dcd"
                      />
                      <h3>Student Accommodation</h3>
                      <p>
                        If you want to be more independent, we recommend staying
                        at together co-iving and student.com. Their quality
                        campus-style accommodation offers modern facilities and
                        an international environment where you will easily make
                        friends while sharing fun activities and quality study
                        time together. The common areas at together co-living
                        and student.com also include a student bar, billiards, a
                        restaurant, a table tennis table, a resort­ style
                        swimming pool and a barbecue area. The rooms are
                        equipped with a microwave, however a common kitchen area
                        is also available in the lodge. Socialising has never
                        been so easy. In fact, free and cheap social activities
                        like movie nights and barbecues are organised to make
                        sure our students enjoy a great social life at a very
                        low cost.
                      </p>
                      <h3>Airport pick up</h3>
                      <p>
                        SMEAG English can arrange an airport pickup service upon
                        request to transfer you from the airport to your
                        homestay or student accommodation.
                      </p>

                      <h4>Q&A</h4>
                      <h6>
                        Q. What is the difference between half board and full
                        board in a Homestay?
                      </h6>
                      <p>
                        Half board homestays provide 2 meals a day. It is
                        usually breakfast and dinner. Full board provides all 3
                        meals a day including lunch or a lunch box.
                      </p>
                      <h6>
                        Q. How far is the student accommodation from SMART
                        English Melbourne?
                      </h6>
                      <p>
                        student.com has several accomodations that you can
                        choose in Melbourne CBD Area. You can choose any
                        suitable accomodation for your personal needs. together
                        co-living will take 30 minutes’ travel time. Homestays
                        are usually located with 30 ~ 45 min of travel time.
                      </p>
                      <h6>Q. Where do I meet my airport pickup?</h6>
                      <p>
                        Our airport pickup service provider will send you the
                        meeting time and meeting location with the welcome
                        package. Our pickup service provider will wait for you
                        at the exit with a SMART English sign.
                      </p>
                      <h6>Q. What if I miss my plane?</h6>
                      <p>
                        Please send us an email or call the emergency contact
                        with your new arrival time and flight details as soon as
                        you have them.
                      </p>
                      <div className="ttr-divider bg-gray">
                        <i className="icon-dot c-square"></i>
                      </div>
                    </div>
                  </div>
                  <div className="recent-news blog-lg" id="prearrival">
                    <div className="action-box blog-lg">
                      <h3>Pre - Arrival Information</h3>
                      <img
                        src="http://www.smeag.com.au/student-services/images/7-SERVICES.jpg"
                        alt="dccd"
                      />
                    </div>
                    <div className="info-bx">
                      <p>
                        Prior to visiting Australia, make sure you hold a valid
                        Australian visa. Please find more information about
                        eligibility and conditions at www.homeaffairs.gov.au You
                        may also study for a limited time on a Visitor or
                        Working Holiday Visa. If you are unsure on how to
                        proceed, we have a range of educational agent partners
                        that will be happy to assist you with the process of
                        your visa application.
                      </p>
                      <p>
                        The establishment of the Home Affairs Portfolio brings
                        together Australia’s federal law enforcement, national
                        and transport security, criminal justice, emergency
                        management, multicultural affairs and immigration and
                        border-related functions and agencies, working together
                        to keep Australia safe.{" "}
                        <a href="www.homeaffairs.gov.au">
                          More information here
                        </a>
                      </p>
                      <div className="ttr-divider bg-gray">
                        <i className="icon-dot c-square"></i>
                      </div>
                    </div>
                  </div>
                  <div className="recent-news blog-lg" id="activity">
                    <div className="action-box blog-lg">
                      <h3>Student Activites</h3>
                      <img
                        src="http://www.smeag.com.au/student-services/images/6-SERVICES.jpg"
                        alt="dccd"
                      />
                    </div>
                    <div className="info-bx">
                      <p>
                        At SMART English we understand the challenges and
                        struggles of learning English in a foreign country.
                        Students can rest assured that we offer support before
                        they leave their country and throughout their stay in
                        Australia.
                      </p>
                      <p>
                        SMART English staff members are available to assist
                        students on academic and personal issues and will do
                        their best to help students make friends through fun
                        social activities, tours and excursions to visit the
                        best sights Melbourne has to offer. Give us feedback on
                        how we can improve our student services at SMART English
                        by telling us what you would like to do and we will make
                        it happen.
                      </p>
                      <p>
                        We love all our students and that’s why we welcome our
                        current and past students to join our activities
                        anytime, so stay in touch with us even after you have
                        finished your course.
                      </p>
                      <img
                        src="http://www.smeag.com.au/student-services/images/1-STUDENT-ACT.png"
                        alt="cdc"
                      />
                      <div className="ttr-divider bg-gray">
                        <i className="icon-dot c-square"></i>
                      </div>
                    </div>
                  </div>
                  <div className="recent-news blog-lg" id="welfare">
                    <div className="action-box blog-lg">
                      <h3>Academic and Welfare Support</h3>
                      <img
                        src="http://www.smeag.com.au/student-services/images/8-SERVICES.jpg"
                        alt="dccd"
                      />
                    </div>
                    <div className="info-bx">
                      <p>
                        At SMART English we understand the challenges and
                        struggles of learning English in a foreign country.
                        Students may rest assured that we offer support before
                        they leave their country and throughout their stay in
                        Australia.
                      </p>
                      <p>
                        SMART English staff members are available to assist
                        students on academic and personal issues. Teachers will
                        identify students who need extra academic support and
                        will liaise with the academic team to monitor their
                        progress through their course of study. Give us feedback
                        on how we can improve our student services at SMART
                        English by telling us what you would like to do and we
                        will make it happen.
                      </p>
                      <div className="ttr-divider bg-gray">
                        <i className="icon-dot c-square"></i>
                      </div>
                    </div>
                  </div>
                  <div className="recent-news blog-lg" id="job">
                    <div className="action-box blog-lg">
                      <h3>Job Club</h3>
                      <img
                        src="http://www.smeag.com.au/student-services/images/9-SERVICES.jpg"
                        alt="dccd"
                      />
                    </div>
                    <div className="info-bx">
                      <p>
                        Job club is a free weekly one hour session where our
                        students are assisted while writing up their resumes or
                        cover letters.
                      </p>
                      <p>
                        Our teacher will focus on interview skills, fluency,
                        vocabulary and pronunciation so that our students feel
                        at ease with the whole interview experience in a foreign
                        country. Our student support staff members are also
                        available to assist with Tax File Numbers (TFN),
                        Australian Business Numbers (ABN) and other
                        administrative paperwork for working in Australia. We
                        also have a wide range of partners operating in several
                        industries that offer students job positions when
                        available.
                      </p>
                      <div className="ttr-divider bg-gray">
                        <i className="icon-dot c-square"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-4">
                  <BlogAside />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ServicesDetails;

import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";

// Images
import bannerImg from "../../images/banner/banner2.jpg";
import testiPic1 from "../../images/testimonials/pic1.jpg";
import testiPic2 from "../../images/testimonials/pic2.jpg";
import blogDefaultThum1 from "../../images/blog/default/thum1.jpg";

const CoursesDetails = (props) => {
  let data = props.location.state;
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">{data.title}</h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>{data.slug}</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="section-area section-sp1">
            <div className="container">
              <div className="row d-flex flex-row-reverse">
                <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 m-b30">
                  <div className="course-detail-bx">
                    <div className="course-price">
                      {/* <del>$500</del> */}
                      <h4 className="price">$320</h4>
                    </div>
                    <div className="course-buy-now text-center">
                      <Link to="/contact" className="btn radius-xl">
                        Buy Now This Courses
                      </Link>
                    </div>
                    <div className="teacher-bx">
                      <div className="teacher-info">
                        <div className="teacher-thumb">
                          <img
                            src="https://res.cloudinary.com/do3ftr3oe/image/upload/v1637033970/favicon_00177b87f2.png"
                            alt="logo"
                          />
                        </div>
                        <div className="teacher-name">
                          <h5>Smartie</h5>
                          <span>English</span>
                        </div>
                      </div>
                    </div>
                    <div className="cours-more-info">
                      <div className="review">
                        <span>5 Stars</span>
                        <ul className="cours-star">
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="price categories">
                        <span>Categories</span>
                        <h5 className="text-primary">English</h5>
                      </div>
                    </div>
                    {/* <div className="course-info-list scroll-page">
                      <ul className="navbar">
                        <li>
                          <ScrollTo
                            smooth={true}
                            activeClass="active"
                            spy={true}
                            className="nav-link"
                            to={"overview"}
                          >
                            <i className="ti-zip"></i> Overview
                          </ScrollTo>
                        </li>
                        <li>
                          <ScrollTo
                            smooth={true}
                            activeClass="active"
                            spy={true}
                            className="nav-link"
                            to={"curriculum"}
                          >
                            <i className="ti-bookmark-alt"></i> Curriculum
                          </ScrollTo>
                        </li>
                        <li>
                          <ScrollTo
                            smooth={true}
                            activeClass="active"
                            spy={true}
                            className="nav-link"
                            to={"instructor"}
                          >
                            <i className="ti-user"></i> Instructor
                          </ScrollTo>
                        </li>
                        <li>
                          <ScrollTo
                            smooth={true}
                            activeClass="active"
                            spy={true}
                            className="nav-link"
                            to={"reviews"}
                          >
                            <i className="ti-comments"></i> Reviews
                          </ScrollTo>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>

                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
                  <div className="courses-post">
                    <div className="ttr-post-media media-effect">
                      <img src={data.image.url} alt="ddd" />
                    </div>
                    <ReactMarkdown children={data.Content} />
                  </div>
                  <div className="" id="instructor">
                    <h4>Instructor</h4>
                    <div className="instructor-bx">
                      <div className="instructor-author">
                        <img
                          src="https://res.cloudinary.com/do3ftr3oe/image/upload/v1637033970/favicon_00177b87f2.png"
                          alt="logo"
                        />
                      </div>
                      <div className="instructor-info">
                        <h6>Smartie </h6>
                        <span>Professor</span>
                        <ul className="list-inline m-tb10">
                          <li>
                            <Link to="#" className="btn sharp-sm facebook">
                              <i className="fa fa-facebook"></i>
                            </Link>
                          </li>

                          <li>
                            <Link to="#" className="btn sharp-sm linkedin">
                              <i className="fa fa-linkedin"></i>
                            </Link>
                          </li>
                        </ul>
                        <p className="m-b0"></p>
                      </div>
                    </div>
                  </div>
                  <div className="" id="reviews">
                    <h4>Stars</h4>

                    <div className="review-bx">
                      <div className="all-review">
                        <h2 className="rating-type">5</h2>
                        <ul className="cours-star">
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                          <li className="active">
                            <i className="fa fa-star"></i>
                          </li>
                        </ul>
                        <span>5 Stars</span>
                      </div>
                      <div className="review-bar">
                        <div className="bar-bx">
                          <div className="side">
                            <div>5 star</div>
                          </div>
                          <div className="middle">
                            <div className="bar-container">
                              <div
                                className="bar-5"
                                style={{ width: "90%" }}
                              ></div>
                            </div>
                          </div>
                          <div className="side right">
                            <div>150</div>
                          </div>
                        </div>
                        <div className="bar-bx">
                          <div className="side">
                            <div>4 star</div>
                          </div>
                          <div className="middle">
                            <div className="bar-container">
                              <div
                                className="bar-5"
                                style={{ width: "70%" }}
                              ></div>
                            </div>
                          </div>
                          <div className="side right">
                            <div>140</div>
                          </div>
                        </div>
                        <div className="bar-bx">
                          <div className="side">
                            <div>3 star</div>
                          </div>
                          <div className="middle">
                            <div className="bar-container">
                              <div
                                className="bar-5"
                                style={{ width: "50%" }}
                              ></div>
                            </div>
                          </div>
                          <div className="side right">
                            <div>120</div>
                          </div>
                        </div>
                        <div className="bar-bx">
                          <div className="side">
                            <div>2 star</div>
                          </div>
                          <div className="middle">
                            <div className="bar-container">
                              <div
                                className="bar-5"
                                style={{ width: "40%" }}
                              ></div>
                            </div>
                          </div>
                          <div className="side right">
                            <div>110</div>
                          </div>
                        </div>
                        <div className="bar-bx">
                          <div className="side">
                            <div>1 star</div>
                          </div>
                          <div className="middle">
                            <div className="bar-container">
                              <div
                                className="bar-5"
                                style={{ width: "20%" }}
                              ></div>
                            </div>
                          </div>
                          <div className="side right">
                            <div>80</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CoursesDetails;

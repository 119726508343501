import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

// Images
import logo from "../../../images/logo.webp";
import adv from "../../../images/brochure.png";

class Header extends Component {
  componentDidMount() {
    // Search Form Popup
    var searchBtn = document.getElementById("quik-search-btn");
    var searchForm = document.querySelector(".nav-search-bar");
    var closeBtn = document.getElementById("search-remove");

    searchBtn.addEventListener("click", function () {
      searchForm.classList.add("show");
    });

    closeBtn.addEventListener("click", function () {
      searchForm.classList.remove("show");
    });

    // Mobile Menu sidebar function
    var btn = document.querySelector(".menuicon");
    var nav = document.querySelector(".menu-links");

    function toggleFunc() {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    }

    btn.addEventListener("click", toggleFunc);

    // Mobile Submenu open close function
    var navMenu = [].slice.call(
      document.querySelectorAll(".menu-links > ul > li")
    );
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener("click", function () {
        menuClick(this);
      });
    }

    function menuClick(current) {
      const active = current.classList.contains("open");
      navMenu.forEach((el) => el.classList.remove("open"));

      if (active) {
        current.classList.remove("open");
        console.log("active");
      } else {
        current.classList.add("open");
        console.log("close");
      }
    }
  }

  render() {
    return (
      <>
        <header className="header rs-nav header-transp arent">
          <Sticky enabled={true} className="sticky-header navbar-expand-lg">
            <div className="menu-bar clearfix">
              <div className="container clearfix">
                {/* <!-- Header Logo ==== --> */}
                <div className="menu-logo">
                  <Link to="/">
                    <img
                      src="https://sem.ebizlab.co/wp-content/uploads/2021/10/SE-Landscape-Logo-NB-Melbourne.png"
                      alt="logo"
                    />
                  </Link>
                </div>
                {/* <!-- Mobile Nav Button ==== --> */}
                <button
                  className="navbar-toggler collapsed menuicon justify-content-end"
                  type="button"
                  data-toggle="collapse"
                  data-target="#menuDropdown"
                  aria-controls="menuDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                {/* <!-- Author Nav ==== --> */}
                <div className="secondary-menu">
                  <div className="secondary-inner">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/SMART-English-Melbourne-106813281816034"
                          className="btn-link"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/smart_english_melbourne/"
                          className="btn-link"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                      {/* <!-- Search Button ==== --> */}
                      <li className="search-btn">
                        <button
                          id="quik-search-btn"
                          type="button"
                          className="btn-link"
                          style={{ visibility: "hidden" }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- Navigation Menu ==== --> */}
                <div
                  className="menu-links navbar-collapse collapse justify-content-start"
                  id="menuDropdown"
                >
                  <div className="menu-logo">
                    <Link to="/">
                      <img src={logo} alt="" />
                    </Link>
                  </div>
                  <ul className="nav navbar-nav">
                    <li className="active">
                      <Link to="/">Home </Link>
                    </li>
                    <li>
                      <Link to="/about-us">About Us </Link>
                    </li>
                    <li>
                      <Link to="/service">Service </Link>
                    </li>
                    <li>
                      <Link to="/event">Events</Link>
                    </li>
                    <li className="add-mega-menu">
                      <Link to="#">
                        Courses <i className="fa fa-chevron-down"></i>
                      </Link>
                      <ul className="sub-menu add-menu">
                        <li className="add-menu-left">
                          <h5 className="menu-adv-title">Our Courses</h5>
                          <ul>
                            <li>
                              <Link to="/courses">Courses </Link>
                            </li>
                            <li>
                              <Link to="/courses">General English</Link>
                            </li>
                            <li>
                              <Link to="/courses">
                                English for Academic Purposes
                              </Link>
                            </li>
                            <li>
                              <Link to="/courses">IELTS Preparation</Link>
                            </li>

                            <li>
                              <Link to="/courses">Study Tour</Link>
                            </li>
                            <li>
                              <Link to="/courses">Ready for Australia</Link>
                            </li>
                          </ul>
                        </li>
                        <li className="add-menu-right">
                          <img src={adv} alt="fv" />
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/blog">Blog </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us </Link>
                    </li>
                  </ul>
                  <div className="nav-social-link">
                    <a href="https://www.facebook.com/SMART-English-Melbourne-106813281816034">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/smart_english_melbourne/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </div>
                </div>
                {/* <!-- Navigation Menu END ==== --> */}
              </div>
            </div>
          </Sticky>
          {/* <!-- Search Box ==== --> */}
          <div className="nav-search-bar">
            <form action="#">
              <input
                name="search"
                type="text"
                className="form-control"
                placeholder="Type to search"
              />
              <span>
                <i className="ti-search"></i>
              </span>
            </form>
            <span id="search-remove">
              <i className="ti-close"></i>
            </span>
          </div>
        </header>
      </>
    );
  }
}

export default Header;

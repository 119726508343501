import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Images
import ServicePic1 from "../../images/our-services/pic1.jpg";
import ServicePic2 from "../../images/our-services/pic2.jpg";
import ServicePic3 from "../../images/our-services/pic3.jpg";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const ServicesContent1 = () => {
  const [data, setData] = useState([]);

  const [show, setShow] = useState(true);
  useEffect(() => {
    const URL = `https://smart-english-cms.herokuapp.com/courses`;
    var config = {
      method: "get",
      url: URL,
    };
    axios(config)
      .then(function (response) {
        const result = response.data;
        console.log(result);
        setShow(false);
        setData(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="section-area bg-gray section-sp1 choose-bx">
        <div className="container">
          <div className="row">
            <div className="col-md-12 heading-bx left">
              <h2 className="title-head">
                Our<span> Courses</span>
              </h2>
              <p>To begin your English path today.</p>
            </div>
          </div>
          <div className="row">
            {show === true ? (
              <div className="col-md-12 col-lg-12 col-sm-12 m-b30">
                <Skeleton count={5} />
              </div>
            ) : (
              <>
                {data.slice(2, 5).map((item) => (
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="service-bx m-b30">
                      <div className="action-box">
                        <img src={item.image.url} alt="dcdc" />
                      </div>
                      <div className="info-bx">
                        <h4>
                          <Link
                            to={{
                              pathname: `/courses-details/${item.id}`,
                              state: item,
                            }}
                          >
                            {item.title}
                          </Link>
                        </h4>
                        <p>{item.smallDescription}</p>
                        <Link
                          to={{
                            pathname: `/courses-details/${item.id}`,
                            state: item,
                          }}
                          className="btn radius-xl"
                        >
                          View More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesContent1;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import adv from "../../images/brochure.png";
import blogRecentPic1 from "../../images/blog/recent-blog/pic1.jpg";
import blogRecentPic3 from "../../images/blog/recent-blog/pic3.jpg";
import coursesPic1 from "../../images/courses/pic1.jpg";
import coursesPic2 from "../../images/courses/pic2.jpg";
import coursesPic3 from "../../images/courses/pic3.jpg";
import coursesPic4 from "../../images/courses/pic4.jpg";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const Courses = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const [show, setShow] = useState(true);
  useEffect(() => {
    const URL = `https://smart-english-cms.herokuapp.com/courses`;
    var config = {
      method: "get",
      url: URL,
    };
    axios(config)
      .then(function (response) {
        const result = response.data;
        console.log(result);
        setShow(false);
        setData(result);
        setData2(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">Our Courses</h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Our Courses</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12">
                  {/* <div className="widget widget_archive">
                      <h5 className="widget-title style-1">All Courses</h5>
                      <ul>
                        <li className="active">
                          <Link to="#">Smartie</Link>
                        </li>
                        <li>
                          <Link to="/courses-details">Smartie</Link>
                        </li>
                        <li>
                          <Link to="/courses-details">English</Link>
                        </li>
                      </ul>
                    </div> */}
                  <div className="widget recent-posts-entry widget-courses">
                    <h5 className="widget-title style-1">Recent Courses</h5>
                    <div className="widget-post-bx">
                      {data2.slice(0, 3).map((item) => (
                        <div className="widget-post clearfix">
                          <div className="ttr-post-media">
                            {" "}
                            <img
                              src={item.image.url}
                              width="200"
                              height="143"
                              alt="vfv"
                            />{" "}
                          </div>
                          <div className="ttr-post-info">
                            <div className="ttr-post-header">
                              <h6 className="post-title">
                                <Link
                                  to={{
                                    pathname: `/courses-details/${item.id}`,
                                    state: item,
                                  }}
                                >
                                  {item.title}
                                </Link>
                              </h6>
                            </div>
                            <div className="ttr-post-meta">
                              <ul>
                                {/* <li className="price">
																	<del>$190</del>
																	<h5>$120</h5>
																</li> */}
                                <li className="review">5 Stars</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="widget">
                    <Link to="/download">
                      <img src={adv} alt="dd" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-12">
                  <div className="row">
                    {show === true ? (
                      <div className="col-md-12 col-lg-12 col-sm-12 m-b30">
                        <Skeleton count={5} />
                      </div>
                    ) : (
                      <>
                        {data.map((item) => (
                          <div
                            className="col-md-6 col-lg-6 col-sm-6 m-b30"
                            key={item.id}
                          >
                            <div className="cours-bx">
                              <div className="action-box">
                                <img src={item.image.url} alt="" />
                                <Link
                                  to={{
                                    pathname: `/courses-details/${item.id}`,
                                    state: item,
                                  }}
                                  className="btn"
                                >
                                  Read More
                                </Link>
                              </div>
                              <div className="info-bx">
                                {/* <span>{item.Tag}</span> */}
                                <h6>
                                  <Link
                                    to={{
                                      pathname: `/courses-details/${item.id}`,
                                      state: item,
                                    }}
                                  >
                                    {item.title}
                                  </Link>
                                </h6>
                                <span>{item.smallDescription}</span>
                              </div>
                              <div className="cours-more-info">
                                <div className="review">
                                  <span>5 Stars</span>
                                  <ul className="cours-star">
                                    <li className="active">
                                      <i className="fa fa-star"></i>
                                    </li>
                                    <li className="active">
                                      <i className="fa fa-star"></i>
                                    </li>
                                    <li className="active">
                                      <i className="fa fa-star"></i>
                                    </li>
                                    <li className="active">
                                      <i className="fa fa-star"></i>
                                    </li>
                                    <li className="active">
                                      <i className="fa fa-star"></i>
                                    </li>
                                  </ul>
                                </div>
                                <div className="price">
                                  {/* <del>$500</del> */}
                                  <h5>$320</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    {/* <div className="col-lg-12 m-b20">
                      <div className="pagination-bx rounded-sm gray clearfix">
                        <ul className="pagination">
                          <li className="previous">
                            <Link to="#">
                              <i className="ti-arrow-left"></i> Prev
                            </Link>
                          </li>
                          <li className="active">
                            <Link to="#">1</Link>
                          </li>
                          <li>
                            <Link to="#">2</Link>
                          </li>
                          <li>
                            <Link to="#">3</Link>
                          </li>
                          <li className="next">
                            <Link to="#">
                              Next <i className="ti-arrow-right"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Courses;

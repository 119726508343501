import React, { Component } from "react";
import Slider from "react-slick";
import bg1 from "../../images/background/bg1.jpg";

// Content
const content = [
  {
    thumb:
      "https://sem.ebizlab.co/wp-content/webpc-passthru.php?src=https://sem.ebizlab.co/wp-content/uploads/2021/02/3-SUCCESS-STORY-100x100.jpg&nocache=1",
    name: "Yuriko ",
    text: "I had such a great experience here. David is great and will go out of his way to help you learn. The quality of the lessons are amazing and it is super affordable which was a priority for a broke graduate student like me.",
  },
  {
    thumb:
      "https://sem.ebizlab.co/wp-content/webpc-passthru.php?src=https://sem.ebizlab.co/wp-content/uploads/2021/02/4-SUCCESS-STORY-100x100.jpg&nocache=1",
    name: "Santos",
    text: "I’ve attended classes here for the last few months and have really enjoyed the experience! Pedro is a wonderful teacher and I have already become more confident in speaking English!",
  },
  {
    thumb:
      "https://sem.ebizlab.co/wp-content/webpc-passthru.php?src=https://sem.ebizlab.co/wp-content/uploads/2021/02/5-SUCCESS-STORY-100x100.jpg&nocache=1",
    name: "Peter Parker",
    text: "Staff are extremely helpful and accommodating. I was a bit of a pain and had to switch a couple of classes around and they were very helpful, the tutors are friendly and have a wealth of knowledge to share with you. The courses are very hands-on which I appreciated a lot.",
  },
  {
    thumb:
      "https://sem.ebizlab.co/wp-content/webpc-passthru.php?src=https://sem.ebizlab.co/wp-content/uploads/2021/02/2-SUCCESS-STORY-100x100.jpg&nocache=1",
    name: "Elsie",
    text: "The Language school is really amazing and affordable! The instructors are great as well. I took the first 4 basic English classes and I feel like I learned so much! I definitely recommend this school to anyone who is looking for a language school! ",
  },
  {
    thumb:
      "https://sem.ebizlab.co/wp-content/webpc-passthru.php?src=https://sem.ebizlab.co/wp-content/uploads/2021/02/1-SUCCESS-STORY-100x100.jpg&nocache=1",
    name: "Cathy Kim",
    work: "FRONT-END DEVELOPER",
    text: "LearnPress is a comprehensive LMS solution for WordPress. This WordPress LMS Plugin can be used to easily create & sell courses online. Each course curriculum can be made with lessons & quizzes which can be managed with easy-to-use user interface, it never gets easier with LearnPress. ",
  },
  {
    thumb:
      "https://sem.ebizlab.co/wp-content/webpc-passthru.php?src=https://sem.ebizlab.co/wp-content/uploads/2015/11/manuel-100x100.jpg&nocache=1",
    name: "Manuel",
    work: "DESIGNER",
    text: "LearnPress is a comprehensive LMS solution for WordPress. This WordPress LMS Plugin can be used to easily create & sell courses online. Each course curriculum can be made with lessons & quizzes which can be managed with easy-to-use user interface, it never gets easier with LearnPress. ",
  },
];

class Testimonial1 extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <div
          className="section-area section-sp2 bg-fix ovbl-dark"
          style={{ backgroundImage: "url(" + bg1 + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 heading-bx left text-white">
                <h2 className="title-head">
                  WHAT PEOPLE <span>SAY</span>
                </h2>
                <br />
                <span>How real people said about Smart English.</span>
              </div>
            </div>

            <Slider
              {...settings}
              className="testimonial-carousel slick-slider owl-btn-1"
            >
              {content.map((item) => (
                <div className="slider-item">
                  <div className="testimonial-bx">
                    <div className="testimonial-head">
                      <div className="testimonial-thumb">
                        <img src={item.thumb} alt="" />
                      </div>
                      <div className="testimonial-info">
                        <h5 className="name">{item.name}</h5>
                        <span>{item.work}</span>
                      </div>
                    </div>
                    <div className="testimonial-content">
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonial1;

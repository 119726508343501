import React, { Component } from "react";
import Slider from "react-slick";

// Images
import testiPic1 from "../../images/testimonials/1.webp";
import testiPic2 from "../../images/testimonials/2.webp";
import testiPic3 from "../../images/testimonials/3.webp";
import testiPic4 from "../../images/testimonials/4.webp";
import testiPic5 from "../../images/testimonials/5.webp";
import testiPic6 from "../../images/testimonials/6.webp";
import testiPic7 from "../../images/testimonials/7.webp";
import testiPic8 from "../../images/testimonials/8.webp";

// Content
const content = [
  {
    thumb: testiPic1,
    name: "Elly Beolchin",
    work: "DIRECTOR OF STUDIES",
  },
  {
    thumb: testiPic8,
    name: "Karen West",
    work: "ENGLISH LANGUAGE TEACHER",
  },
  {
    thumb: testiPic3,
    name: "Sam Marshall",
    work: "ENGLISH LANGUAGE TEACHER",
  },
  {
    thumb: testiPic5,
    name: "Elena Claydon",
    work: "ENGLISH LANGUAGE TEACHER",
  },
  {
    thumb: testiPic2,
    name: "Timothy Dickinson",
    work: "ENGLISH LANGUAGE TEACHER",
  },
  {
    thumb: testiPic4,
    name: "Gabriel Mentz",
    work: "ENGLISH LANGUAGE TEACHER",
  },
  {
    thumb: testiPic7,
    name: "Natalia Raksimowicz",
    work: "ENGLISH LANGUAGE TEACHER",
  },
  {
    thumb: testiPic6,
    name: "Heather Mason",
    work: "ENGLISH LANGUAGE TEACHER",
  },
];

class Testimonial3 extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <div className="section-area section-sp2">
          <div className="container">
            <div className="row">
              <div className="col-md-12 heading-bx left">
                <h2 className="title-head">
                  MEET OUR <span>TEAM</span>
                </h2>
                <br />
                <span>Plugins your themes with even more features.</span>
              </div>
            </div>

            <div className="row">
              {content.map((item) => (
                <div className="col-lg-3 col-md-4 col-sm-6 m-b30">
                  <img src={item.thumb} alt="ss" />
                  <div className="testimonial-info text-center mt-2 mr-5">
                    <h5 className="name">{item.name}</h5>
                    <p>{item.work}</p>
                  </div>
                  {/* <div className="testimonial-content">
                        <p>{item.work}</p>
                      </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonial3;

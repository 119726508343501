import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Images
import blogPic1 from "../../images/blog/latest-blog/pic1.jpg";
import blogPic2 from "../../images/blog/latest-blog/pic2.jpg";
import blogPic3 from "../../images/blog/latest-blog/pic3.jpg";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

// Content
const content = [
  {
    thumb: blogPic1,
    title: "Smartie",
    text: "I want a real English teacher!",
  },
  {
    thumb: blogPic1,
    title: "Smartie",
    text: "Start reading in English!",
  },
  {
    thumb: blogPic1,
    title: "Smartie",
    text: "The race that stop a nation",
  },
  {
    thumb: blogPic1,
    title: "Smartie",
    text: "free Walking Tours In Melbourne",
  },
];

const RecentNewsSlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [data, setData] = useState([]);
  const [show, setShow] = useState(true);
  useEffect(() => {
    const URL = `https://smart-english-cms.herokuapp.com/blogs`;
    var config = {
      method: "get",
      url: URL,
    };
    axios(config)
      .then(function (response) {
        const result = response.data;
        console.log(result);
        setShow(false);
        setData(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="section-area section-sp1">
        <div className="container">
          <div className="row">
            <div className="col-md-12 heading-bx left">
              <h2 className="title-head">
                Recent <span>News</span>
              </h2>
              <p>Education news all over the world.</p>
            </div>
          </div>
          <Slider
            {...settings}
            className="recent-news-carousel slick-slider owl-btn-1"
          >
            {data.map((item) => (
              <div className="slider-item">
                <div className="recent-news">
                  <div className="action-box">
                    <img src={item.image.url} alt="dcc" />
                  </div>
                  <div className="info-bx">
                    <ul className="media-post">
                      <li>
                        <Link
                          to={{
                            pathname: `/blog-details/${item.id}`,
                            state: item,
                          }}
                        >
                          <i className="fa fa-calendar"></i>
                          {item.date}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={{
                            pathname: `/blog-details/${item.id}`,
                            state: item,
                          }}
                        >
                          <i className="fa fa-user"></i>By {item.author}
                        </Link>
                      </li>
                    </ul>
                    <h5 className="post-title">
                      <Link
                        to={{
                          pathname: `/blog-details/${item.id}`,
                          state: item,
                        }}
                      >
                        {item.title}
                      </Link>
                    </h5>
                    <p>{item.text}</p>
                    <div className="post-extra">
                      <Link
                        to={{
                          pathname: `/blog-details/${item.id}`,
                          state: item,
                        }}
                        className="btn-link"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default RecentNewsSlider;

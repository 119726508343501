import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

// Images
import logo from "../../../images/logo.webp";
import logo2 from "../../../images/1.png";
import img from "../../../images/s/1.jpg";
import img2 from "../../../images/s/2.jpg";
import img3 from "../../../images/s/3.jpg";
import img4 from "../../../images/s/4.jpg";
import img5 from "../../../images/s/5.jpg";
import img6 from "../../../images/s/6.jpg";
import img7 from "../../../images/s/7.jpg";
import img8 from "../../../images/s/8.jpg";
import emailjs from "emailjs-com";

const content = [
  {
    thumb: img,
  },
  {
    thumb: img2,
  },
  {
    thumb: img3,
  },
  {
    thumb: img4,
  },
  {
    thumb: img5,
  },
  {
    thumb: img6,
  },
  {
    thumb: img7,
  },
  {
    thumb: img8,
  },
];

const options = {
  settings: {
    overlayColor: "rgba(0,0,0,0.9)",
    backgroundColor: "#FDC716",
    slideAnimationType: "slide",
  },
  buttons: {
    backgroundColor: "#f7b205",
    iconColor: "rgba(255, 255, 255, 1)",
    showDownloadButton: false,
    showAutoplayButton: false,
    showThumbnailsButton: false,
  },
  caption: {
    captionColor: "#232eff",
    captionFontFamily: "Raleway, sans-serif",
    captionFontWeight: "300",
    captionTextTransform: "uppercase",
  },
};

const Footer1 = () => {
  function GalleryImg() {
    return (
      <>
        <SimpleReactLightbox>
          <SRLWrapper options={options}>
            <ul className="magnific-image">
              {content.map((item) => (
                <li key={item.thumb}>
                  <img
                    src={item.thumb}
                    style={{ height: "50px", width: "60px" }}
                    alt="cdc"
                  />
                </li>
              ))}
            </ul>
          </SRLWrapper>
        </SimpleReactLightbox>
      </>
    );
  }

  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);

  const sendMessage = (e) => {
    setShow(true);
    e.preventDefault();
    const templateParams = {
      from_name: email,
      to_name: "Newsletter",
      phone: "Newsletter",
      message: "The above mail is for newsletter",
    };
    emailjs
      .send(
        "service_v9yqgwo",
        "template_gr87kv6",
        templateParams,
        "user_tnI1aXlOKY1uW1NgNDREQ"
      )
      .then(
        function (response) {
          setShow(false);
          alert("You have successfully signed up for newsletter");
          console.log("SUCCESS!", response.status, response.text);
          setEmail("");
        },
        function (err) {
          setShow(false);
          alert("Oops... " + JSON.stringify(err));
        }
      );
  };

  return (
    <>
      <footer>
        <div className="footer-top">
          <div className="pt-exebar">
            <div className="container">
              <div className="d-flex align-items-stretch">
                <div className="pt-logo mr-auto">
                  <Link to="/">
                    <img src={logo} alt="logo" style={{ width: "240px" }} />
                  </Link>
                  <a
                    href="https://neas.org.au/quality-centres/quality-centres-state-vic/"
                    target="_blank"
                  >
                    <img
                      src={logo2}
                      alt="logo"
                      style={{ width: "200px", marginLeft: "10px" }}
                    />
                  </a>
                </div>
                <div className="pt-social-link">
                  <ul className="list-inline m-a0">
                    <li>
                      <a
                        href="https://www.facebook.com/SMART-English-Melbourne-106813281816034"
                        className="btn-link"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/smart_english_melbourne/"
                        className="btn-link"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="pt-btn-join">
                  <Link to="/contact-us" className="btn">
                    Let's Talk
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 footer-col-4">
                <div className="widget">
                  <h6 className="footer-title">Sign Up For A Newsletter</h6>
                  <p className="text-capitalize m-b20">
                    Subscribe now and receive weekly newsletter with educational
                    materials, new courses, interesting posts, popular books and
                    much more!
                  </p>
                  <div className="subscribe-form m-b20">
                    <form className="subscription-form" onSubmit={sendMessage}>
                      <div className="ajax-message"></div>
                      <div className="input-group">
                        <input
                          name="email"
                          required="required"
                          className="form-control"
                          placeholder="Your Email Address"
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <span className="input-group-btn">
                          {show ? (
                            <img
                              src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif"
                              alt="loader"
                              style={{ height: "40px" }}
                            />
                          ) : (
                            <button
                              name="submit"
                              value="Submit"
                              type="submit"
                              className="btn"
                            >
                              <i className="fa fa-arrow-right"></i>
                            </button>
                          )}
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 col-md-7 col-sm-12">
                <div className="row">
                  <div className="col-6 col-lg-6 col-md-6 col-sm-6">
                    <div className="widget footer_widget">
                      <h6 className="footer-title">Company</h6>
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about-us">About</Link>
                        </li>
                        <li>
                          <a href="https://wc.rtomanager.com.au/Publics/PublicsPages/AgentListByCountry.aspx">
                            Agent List
                          </a>
                        </li>
                        <li>
                          <Link to="/contact-us">Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 col-lg-6 col-md-6 col-sm-6">
                    <div className="widget footer_widget">
                      <h6 className="footer-title">Get In Touch</h6>
                      <ul>
                        <li>
                          <Link to="/event">Events</Link>
                        </li>
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                          <Link to="/courses">Courses</Link>
                        </li>
                        <li>
                          <Link to="/download">Download</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-5 col-sm-12 footer-col-4">
                <div className="widget widget_gallery gallery-grid-4">
                  <h5 className="footer-title">Our Gallery</h5>
                  <GalleryImg />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                {" "}
                © Windsor College Pty. Ltd. trading as Smart English Melbourne |
                RTO No. 41090 | CRICOS Code 03560K |{" "}
                <span className="text-white">Smart English</span> All Rights
                Reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer1;

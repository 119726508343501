import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";

// Elements
import BlogAside from "../elements/blog-aside";

// Images
import bannerImg from "../../images/banner/banner2.jpg";
import blogPic1 from "../../images/blog/default/thum1.jpg";
import testiPic1 from "../../images/testimonials/pic1.jpg";
import testiPic2 from "../../images/testimonials/pic2.jpg";
import testiPic3 from "../../images/testimonials/pic3.jpg";

const BlogDetails = (props) => {
  let data = props.location.state;
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">{data.title}</h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>{data.title}</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="section-area section-sp2">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-xl-8">
                  <div className="recent-news blog-lg">
                    <div className="action-box blog-lg">
                      <img src={data.image.url} alt="dccd" />
                    </div>
                    <div className="info-bx">
                      <ul className="media-post">
                        <li>
                          <Link to="#">
                            <i className="fa fa-calendar"></i>
                            {data.date}
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fa fa-user"></i>
                            {data.author}
                          </Link>
                        </li>
                      </ul>
                      <ReactMarkdown children={data.content} />

                      <div className="ttr-divider bg-gray">
                        <i className="icon-dot c-square"></i>
                      </div>
                      <div className="widget_tag_cloud">
                        <h6>TAGS</h6>
                        <div className="tagcloud">
                          <Link to="/blog">General English</Link>
                          <Link to="/blog">IELTS</Link>
                          <Link to="/blog">PTE</Link>
                          <Link to="/blog">Australia</Link>
                          <Link to="/blog">Melbourne</Link>
                          <Link to="/blog">Study in Melbourne</Link>
                          <Link to="/blog">Learn English</Link>
                          <Link to="/blog">Study Tour</Link>
                          <Link to="/blog">IELTS Preparation</Link>
                          <Link to="/blog">English for Academic Purposes</Link>
                          <Link to="/blog">Cambridge English</Link>
                          <Link to="/blog">EAP</Link>
                          <Link to="/blog">Hotel Internship</Link>
                          <Link to="/blog">Aufair</Link>
                          <Link to="/blog">Online English</Link>
                          <Link to="/blog">Demi fair</Link>
                          <Link to="/blog">Cambridge FCE</Link>
                          <Link to="/blog">Cambridge CAE</Link>
                        </div>
                      </div>
                      <div className="ttr-divider bg-gray">
                        <i className="icon-dot c-square"></i>
                      </div>
                      <h6>SHARE </h6>
                      <ul className="list-inline contact-social-bx">
                        <li>
                          <a
                            href="https://www.facebook.com/SMART-English-Melbourne-106813281816034"
                            className="btn outline radius-xl"
                          >
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/smart_english_melbourne/"
                            className="btn outline radius-xl"
                          >
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                      <div className="ttr-divider bg-gray">
                        <i className="icon-dot c-square"></i>
                      </div>
                    </div>
                  </div>
                  {/* <div className="clear" id="comment-list">
                      <div className="comments-area" id="comments">
                        <h4 className="comments-title">1 Comments</h4>
                        <div className="clearfix m-b20">
                          <ol className="comment-list">
                            <li className="comment">
                              <div className="comment-body">
                                <div className="comment-author vcard">
                                  <img
                                    className="avatar photo"
                                    src={testiPic1}
                                    alt=""
                                  />
                                  <cite className="fn">John Doe</cite>
                                  <span className="says">says:</span>
                                </div>
                                <div className="comment-meta">
                                  <Link to="#">
                                    December 02, 2019 at 10:45 am
                                  </Link>
                                </div>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Nam vitae neqnsectetur
                                  adipiscing elit. Nam viae neqnsectetur
                                  adipiscing elit. Nam vitae neque vitae sapien
                                  malesuada aliquet.{" "}
                                </p>
                                <div className="reply">
                                  <Link to="#" className="comment-reply-link">
                                    Reply
                                  </Link>
                                </div>
                              </div>
                            </li>
                          </ol>
                          <div className="comment-respond" id="respond">
                            <h4
                              className="comment-reply-title"
                              id="reply-title"
                            >
                              Leave a Reply{" "}
                              <small>
                                {" "}
                                <Link
                                  style={{ display: "none" }}
                                  to="#"
                                  id="cancel-comment-reply-link"
                                  rel="nofollow"
                                >
                                  Cancel reply
                                </Link>{" "}
                              </small>{" "}
                            </h4>
                            <form className="comment-form">
                              <p className="comment-form-author">
                                <label htmlFor="author">
                                  Name <span className="required">*</span>
                                </label>
                                <input
                                  type="text"
                                  value=""
                                  name="Author"
                                  placeholder="Author"
                                  id="author"
                                />
                              </p>
                              <p className="comment-form-email">
                                <label htmlFor="email">
                                  Email <span className="required">*</span>
                                </label>
                                <input
                                  type="text"
                                  value=""
                                  placeholder="Email"
                                  name="email"
                                  id="email"
                                />
                              </p>
                              <p className="comment-form-url">
                                <label for="url">Website</label>
                                <input
                                  type="text"
                                  value=""
                                  placeholder="Website"
                                  name="url"
                                  id="url"
                                />
                              </p>
                              <p className="comment-form-comment">
                                <label htmlFor="comment">Comment</label>
                                <textarea
                                  rows="8"
                                  name="comment"
                                  placeholder="Comment"
                                  id="comment"
                                ></textarea>
                              </p>
                              <p className="form-submit">
                                <input
                                  type="submit"
                                  value="Submit Comment"
                                  className="submit"
                                  name="submit"
                                />
                              </p>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div> */}
                </div>
                <div className="col-lg-4 col-xl-4">
                  <BlogAside />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BlogDetails;

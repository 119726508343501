import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";
import Counter1 from "../elements/counter/counter1";

// Images
import bannerImg from "../../images/banner/banner1.jpg";
import axios from "axios";

const BlogClassicGrid = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(true);
  useEffect(() => {
    const URL = `https://smart-english-cms.herokuapp.com/blogs`;
    var config = {
      method: "get",
      url: URL,
    };
    axios(config)
      .then(function (response) {
        const result = response.data;
        console.log(result);
        setShow(false);
        setData(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">Blog </h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Blog</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="section-area section-sp2">
            <div className="container">
              <div className="ttr-blog-grid-3 row" id="masonry">
                {show === true ? (
                  <div className="post action-card col-lg-12 col-md-12 col-sm-12 col-xs-12 m-b40">
                    <Skeleton count={5} />
                  </div>
                ) : (
                  <>
                    {data.map((item) => (
                      <div
                        className="post action-card col-lg-4 col-md-6 col-sm-12 col-xs-12 m-b40"
                        key={item.id}
                      >
                        <div className="recent-news">
                          <div className="action-box">
                            <img src={item.image.url} alt="blogimage" />
                          </div>
                          <div className="info-bx">
                            <ul className="media-post">
                              <li>
                                <Link
                                  to={{
                                    pathname: `/blog-details/${item.id}`,
                                    state: item,
                                  }}
                                >
                                  <i className="fa fa-calendar"></i>
                                  {item.date}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={{
                                    pathname: `/blog-details/${item.id}`,
                                    state: item,
                                  }}
                                >
                                  <i className="fa fa-user"></i>By &nbsp;
                                  {item.author}
                                </Link>
                              </li>
                            </ul>
                            <h5 className="post-title">
                              <Link
                                to={{
                                  pathname: `/blog-details/${item.id}`,
                                  state: item,
                                }}
                              >
                                {item.title}
                              </Link>
                            </h5>
                            <p>{item.title}</p>
                            <div className="post-extra">
                              <Link
                                to={{
                                  pathname: `/blog-details/${item.id}`,
                                  state: item,
                                }}
                                className="btn-link"
                              >
                                Read More
                              </Link>
                              {/* <Link to="/blog-details" className="comments-bx">
                                <i className="fa fa-comments-o"></i>{" "}
                                {item.BlogComment}
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Counter1 />

      <Footer />
    </>
  );
};

export default BlogClassicGrid;

import React, { Component } from "react";
import { Link } from "react-router-dom";
// Images
import aboutPic1 from "../../../images/about/pic1.jpg";
import { Accordion, Card } from "react-bootstrap";

class OurStory1 extends Component {
  render() {
    return (
      <>
        <div className="section-area bg-gray section-sp1 our-story">
          <div className="container">
            <div className="row align-items-center d-flex">
              <div className="col-lg-7 col-md-12 heading-bx p-lr">
                <div className="video-bx">
                  <img src={aboutPic1} alt="" />
                </div>
              </div>
              <div className="col-lg-5 col-md-12 m-b30">
                <div className="heading-bx left mb-3">
                  <h2 className="title-head">You have got questions</h2>
                </div>
                <Accordion className="ttr-accordion m-b30 faq-bx">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <Link to="#" className="acod-title">
                        Question 1
                      </Link>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Architecto, quae.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion className="ttr-accordion m-b30 faq-bx">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <Link to="#" className="acod-title">
                        Question 2
                      </Link>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Architecto, quae.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurStory1;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";

// Elements
import Counter2 from "../elements/counter/counter2";
import Testimonial3 from "../elements/testimonial2";
import "react-loading-skeleton/dist/skeleton.css";

// Images
import bannerImg from "../../images/banner/banner1.jpg";
import axios from "axios";

const FAQ1 = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(true);
  useEffect(() => {
    const URL = `https://smart-english-cms.herokuapp.com/documents`;
    var config = {
      method: "get",
      url: URL,
    };
    axios(config)
      .then(function (response) {
        const result = response.data;
        console.log(result);
        setShow(false);
        setData(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">Download Documents</h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>downloads</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-12 m-b30">
                  <h3 className="m-b15">
                    DOWNLOAD FOR SMART ENGLISH MELBOURNE DOCUMENT
                  </h3>
                  <p className="m-b0">Answer all of your questions</p>
                </div>
                <div className="col-12 m-b30">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td style={{ fontSize: "20px" }}>SEM Brochure</td>
                        <td style={{ fontSize: "20px" }}>
                          <a
                            href="https://drive.google.com/file/d/111-KYZxp8Xk-olONFwJztlu_4p2wBN0z/view"
                            target="_blank"
                          >
                            <i className="fa fa-cloud-download"></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: "20px" }}>
                          SEM Student handbook
                        </td>
                        <td style={{ fontSize: "20px" }}>
                          <a href="#" target="_blank">
                            <i className="fa fa-cloud-download"></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: "20px" }}>
                          SEM Student Application Form
                        </td>
                        <td style={{ fontSize: "20px" }}>
                          <a
                            href="https://drive.google.com/file/d/1QNgfkzGtO9CyebiRCBwV-xX_EFIzPirY/view"
                            target="_blank"
                          >
                            <i className="fa fa-cloud-download"></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: "20px" }}>
                          SEM Student Request Form
                        </td>
                        <td style={{ fontSize: "20px" }}>
                          <a href="#" target="_blank">
                            <i className="fa fa-cloud-download"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {show === true ? (
                  <div className="col-lg-12 col-md-12">
                    <Skeleton count={5} />
                  </div>
                ) : (
                  <>
                    <div className="col-12 m-b30">
                      <h3 className="m-b15">
                        SMART ENGLISH MELBOURNE POLICIES AND PROCEDURES
                      </h3>
                    </div>
                    <table class="table">
                      <tbody>
                        {data.map((doc) => (
                          <tr key={doc.id}>
                            <td style={{ fontSize: "20px", width: "88%" }}>
                              {doc.DocumentName}
                            </td>
                            <td style={{ fontSize: "20px" }}>
                              <a href={doc.DocumentLink} target="_blank">
                                <i className="fa fa-cloud-download"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Counter2 />
      <Testimonial3 />
      <Footer />
    </>
  );
};

export default FAQ1;

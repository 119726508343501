import React, { Component } from "react";

// Layout
import Header from "../layout/header/header2";
import Footer from "../layout/footer/footer1";

// Elements
import MainSlider from "../elements/slider/slider1";
import ServicesContent2 from "../elements/services-content-2";
import OnlineCourses from "../elements/online-courses";
import ServicesContent1 from "../elements/services-content-1";
import Testimonial1 from "../elements/testimonial1";
import UpcomingEventSlider from "../elements/upcoming-event-slider";
import RecentNewsSlider from "../elements/recent-news-slider";
import OurStory1 from "../elements/our-story/our-story1";

class Index extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <MainSlider />

          <div className="content-block" id="content-area">
            <OurStory1 />
            <OnlineCourses />
            <ServicesContent1 />
            <UpcomingEventSlider />
            <Testimonial1 />
            <RecentNewsSlider />
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default Index;

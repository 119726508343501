import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";

// Elements
import ContactInfoCard from "../elements/contact-info-card";

// Images
import bannerImg from "../../images/banner/banner1.jpg";
import blogThum1 from "../../images/blog/default/thum1.jpg";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const EventsDetails = () => {
  const [data, setData] = useState([]);

  const [show, setShow] = useState(true);
  useEffect(() => {
    const URL = `https://smart-english-cms.herokuapp.com/events`;
    var config = {
      method: "get",
      url: URL,
    };
    axios(config)
      .then(function (response) {
        const result = response.data;
        console.log(result, "cddcdcd");
        setShow(false);
        setData(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log(data[0]);
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">Event Details</h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Event Details</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-7 col-sm-12">
                  {show === true ? (
                    <Skeleton count={5} />
                  ) : (
                    <>
                      {data.length !== 0 ? (
                        <>
                          <ReactMarkdown children={data[0].content} />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
                <div className="col-lg-4 col-md-5 col-sm-12 m-b30">
                  <div className="sticky-top">
                    <ContactInfoCard />
                    <div className="map-frame2 m-t30">
                      <Iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12608.108266050305!2d144.954537!3d-37.812835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d496c6eaaab%3A0x15ee9bacb7463d15!2sLevel%204%2F310%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1637203031211!5m2!1sen!2sus"
                        className="align-self-stretch d-flex"
                        allowfullscreen
                      ></Iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default EventsDetails;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";
import OurStory1 from "../elements/our-story/service";
import Counter from "../elements/counter/counter1";
import OurStory2 from "../elements/our-story/service2";

// Images
import bannerImg from "../../images/banner/banner1.jpg";
import ServicesContent2 from "../elements/our-story/service3";

class Profile extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content">
          <div
            className="page-banner ovbl-dark"
            style={{ backgroundImage: "url(" + bannerImg + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry">
                <h1 className="text-white">Student Service</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-row">
            <div className="container">
              <ul className="list-inline">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Student Service</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="content-block" id="content-area">
          <ServicesContent2 />
          {/* <Counter /> */}
          <Counter />

          <OurStory1 />
          {/* <OurStory2 /> */}
        </div>

        <Footer />
      </>
    );
  }
}

export default Profile;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

// Images
import aboutPic1 from "../../../images/about/pic1.jpg";

class OurStory1 extends Component {
  render() {
    return (
      <>
        <div className="section-area bg-gray section-sp1 our-story">
          <div className="container">
            <div className="row align-items-center d-flex">
              <div className="col-lg-5 col-md-12 m-b30">
                <div className="heading-bx left mb-3">
                  <h2 className="title-head">ABOUT SMART ENGLISH</h2>
                  <p>
                    Smart English has the tools to transform your dream of
                    MASTERING English into reality.
                  </p>
                </div>
                <p className="m-b30">
                  “To have a second language is to possess a second soul. ”
                </p>
                <Link to="/contact-us" className="btn">
                  Get Started
                </Link>
              </div>
              <div className="col-lg-7 col-md-12 heading-bx p-lr">
                <div className="video-bx">
                  <img src={aboutPic1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurStory1;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";

// Elements
import EventsContent from "../elements/events-content";

// Images
import bannerImg from "../../images/banner/banner2.jpg";
import Counter1 from "../elements/counter/counter1";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const Events = () => {
  const [data, setData] = useState([]);

  const [show, setShow] = useState(true);
  useEffect(() => {
    const URL = `https://smart-english-cms.herokuapp.com/events`;
    var config = {
      method: "get",
      url: URL,
    };
    axios(config)
      .then(function (response) {
        const result = response.data;
        console.log(result);
        setShow(false);
        setData(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">Events</h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Events</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="section-area section-sp1 gallery-bx">
            <div className="container">
              <EventsContent data={data} />
            </div>
          </div>
        </div>
      </div>
      <Counter1 />

      <Footer />
    </>
  );
};

export default Events;

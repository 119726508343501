import React, { Component } from "react";
import { Link } from "react-router-dom";

// Images
import ServicePic1 from "../../../images/our-services/pic1.jpg";
import ServicePic2 from "../../../images/our-services/pic2.jpg";
import ServicePic3 from "../../../images/our-services/pic3.jpg";

class ServicesContent2 extends Component {
  render() {
    return (
      <>
        <div className="section-area bg-gray section-sp1 choose-bx">
          <div className="container">
            <div className="row">
              <div className="col-md-12 heading-bx left">
                <h2 className="title-head">Our Student Services</h2>
                <p>To begin your English path today.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-bx m-b30">
                  <div className="action-box">
                    <img src={ServicePic1} alt="" />
                  </div>
                  <div className="info-bx">
                    <h4>
                      <Link to="/service-detail">Hotel Internship</Link>
                    </h4>
                    <p>
                      Internships in hospitality and hotellerie around Australia
                      Up to 6 months Eligible for Working Holiday Visa holders
                      Minimum English level requried IELTS 5.0 or equivalent
                      Paid internship according to Australian laws
                    </p>
                    <Link to="/service-detail" className="btn radius-xl">
                      View More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-bx m-b30">
                  <div className="action-box">
                    <img src={ServicePic2} alt="" />
                  </div>
                  <div className="info-bx">
                    <h4>
                      <Link to="/service-detail">Professional Internship</Link>
                    </h4>
                    <p>
                      Internships in Australian Companies Minimum English level
                      required IELTS 5.0 or equivalent Eligible for Student Visa
                      holders Up to 3 months Learn skills on the job
                    </p>
                    <Link to="/service-detail" className="btn radius-xl">
                      View More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-bx m-b30">
                  <div className="action-box">
                    <img src={ServicePic3} alt="" />
                  </div>
                  <div className="info-bx">
                    <h4>
                      <Link to="/service-detail">
                        Aupair / Demipair program
                      </Link>
                    </h4>
                    <p>
                      Improve faster Experience Australian culture No rent to
                      worry about Eligible for Working Holiday Visa holders
                      Choose the best program option based on your needs
                    </p>
                    <Link to="/service-detail" className="btn radius-xl">
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServicesContent2;

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Elements
import BackToTop from "./elements/back-top";
import PageScrollTop from "./elements/page-scroll-top";

// Home Pages
import Index from "./pages/index";

// About Us
import About1 from "./pages/about-us";

// Events
import Events from "./pages/event";
import EventsDetails from "./pages/events-details";

// Faq
import Faq1 from "./pages/faq-1";

// Other Pages
import Portfolio from "./pages/portfolio";
import Profile from "./pages/profile";
import Membership from "./pages/membership";
import Error404 from "./pages/error-404";
import Register from "./pages/register";
import Login from "./pages/login";
import ForgetPassword from "./pages/forget-password";

// Courses
import Courses from "./pages/courses";
import CoursesDetails from "./pages/courses-details";

// Blog Pages
import BlogClassicGrid from "./pages/blog";
import BlogClassicSidebar from "./pages/blog-classic-sidebar";
import BlogListSidebar from "./pages/blog-list-sidebar";
import BlogStandardSidebar from "./pages/blog-standard-sidebar";
import BlogDetails from "./pages/blog-details";

// Contact Us
import Contact1 from "./pages/contact-us";
import Download from "./pages/download";
import Service from "./pages/student-service";
import ServiceDetail from "./pages/services-details";

class Markup extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Switch>
            {/* Home Pages */}
            <Route path="/" exact component={Index} />
            {/* About Us */}
            <Route path="/about-us" exact component={About1} />
            {/* Events */}
            <Route path="/event" exact component={Events} />
            <Route path="/events-details" exact component={EventsDetails} />
            {/* Faq */}
            <Route path="/faq-1" exact component={Faq1} />
            {/* Other Pages */}
            <Route path="/portfolio" exact component={Portfolio} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/membership" exact component={Membership} />
            <Route path="/error-404" exact component={Error404} />
            <Route path="/register" exact component={Register} />
            <Route path="/login" exact component={Login} />
            <Route path="/forget-password" exact component={ForgetPassword} />
            {/* Courses */}
            <Route path="/courses" exact component={Courses} />
            <Route
              path="/courses-details/:id"
              exact
              component={CoursesDetails}
            />
            {/* Blog Pages */}
            <Route path="/blog" exact component={BlogClassicGrid} />
            <Route
              path="/blog-classic-sidebar"
              exact
              component={BlogClassicSidebar}
            />
            <Route
              path="/blog-list-sidebar"
              exact
              component={BlogListSidebar}
            />
            <Route
              path="/blog-standard-sidebar"
              exact
              component={BlogStandardSidebar}
            />
            <Route path="/blog-details/:id" exact component={BlogDetails} />
            {/* Contact Us */}
            <Route path="/contact-us" exact component={Contact1} />
            <Route path="/download" exact component={Download} />{" "}
            <Route path="/service" exact component={Service} />
            <Route path="/service-detail" exact component={ServiceDetail} />
          </Switch>

          <PageScrollTop />
        </BrowserRouter>

        <BackToTop />
      </>
    );
  }
}

export default Markup;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";

// Elements
import Counter2 from "../elements/counter/counter2";
import Testimonial3 from "../elements/testimonial2";
import "react-loading-skeleton/dist/skeleton.css";

// Images
import bannerImg from "../../images/banner/banner1.jpg";
import axios from "axios";

const FAQ1 = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(true);
  useEffect(() => {
    const URL = `https://smart-english-cms.herokuapp.com/faqs`;
    var config = {
      method: "get",
      url: URL,
    };
    axios(config)
      .then(function (response) {
        const result = response.data;
        console.log(result);
        setShow(false);
        setData(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">Frequently Asked Questions</h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Faqs</li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-12 m-b30">
                  <h3 className="m-b15">
                    FREQUENTLY ASKED <span> QUESTIONS</span>
                  </h3>
                  <p className="m-b0">Answer all of your questions</p>
                </div>
                {show === true ? (
                  <div className="col-lg-12 col-md-12">
                    <Skeleton count={5} />
                  </div>
                ) : (
                  <>
                    {data.map((faq) => (
                      <div className="col-lg-6 col-md-12" key={faq.id}>
                        <Accordion className="ttr-accordion m-b30 faq-bx">
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={faq.id}
                            >
                              <Link to="#" className="acod-title">
                                {faq.Question}
                              </Link>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={faq.id}>
                              <Card.Body>{faq.Answer}</Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Counter2 />
      <Testimonial3 />
      <Footer />
    </>
  );
};

export default FAQ1;

import React, { useState } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";
import Footer from "../layout/footer/footer1";

// Elements
import ContactInfoCard from "../elements/contact-info-card";

// Images
import bannerImg from "../../images/banner/banner2.jpg";
import emailjs from "emailjs-com";

const Contact1 = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);

  const sendMessage = (e) => {
    setShow(true);
    e.preventDefault();
    const templateParams = {
      from_name: email,
      to_name: name,
      phone: phone,
      message: message,
    };
    emailjs
      .send(
        "service_v9yqgwo",
        "template_gr87kv6",
        templateParams,
        "user_tnI1aXlOKY1uW1NgNDREQ"
      )
      .then(
        function (response) {
          setShow(false);
          alert("Your mail is sent!");
          console.log("SUCCESS!", response.status, response.text);
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        },
        function (err) {
          setShow(false);
          alert("Oops... " + JSON.stringify(err));
        }
      );
  };
  return (
    <>
      <Header />

      <div className="page-content">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bannerImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry">
              <h1 className="text-white">Contact Us </h1>
            </div>
          </div>
        </div>
        <div className="breadcrumb-row">
          <div className="container">
            <ul className="list-inline">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Contact Us </li>
            </ul>
          </div>
        </div>

        <div className="content-block">
          <div className="page-banner contact-page section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7 m-b30">
                  <form
                    className="contact-bx ajax-form"
                    id="contactForm"
                    onSubmit={sendMessage}
                  >
                    <div className="ajax-message"></div>
                    <div className="heading-bx left">
                      <h3 className="title-head">
                        Get In <span>Touch</span>
                      </h3>
                      <p>
                        Your email address will not be published. Required
                        fields are marked.
                      </p>
                    </div>
                    <div className="row placeani">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="name"
                              type="text"
                              value={name}
                              placeholder="Your Name"
                              required
                              className="form-control valid-character"
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="email"
                              type="email"
                              value={email}
                              placeholder="Your Email Address"
                              className="form-control"
                              required
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="phone"
                              type="text"
                              value={phone}
                              placeholder="Your Phone"
                              required
                              className="form-control int-value"
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="message"
                              type="text"
                              placeholder="Subject"
                              required
                              className="form-control"
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <textarea
                              name="message"
                              value={message}
                              placeholder="Type Message"
                              rows="4"
                              className="form-control"
                              required
                              onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        {show ? (
                          <img
                            src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif"
                            alt="loader"
                            style={{ height: "40px" }}
                          />
                        ) : (
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            class="btn button-md"
                          >
                            {" "}
                            Send Message
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-md-5 m-b30">
                  <ContactInfoCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12608.108266050305!2d144.954537!3d-37.812835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d496c6eaaab%3A0x15ee9bacb7463d15!2sLevel%204%2F310%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1637203031211!5m2!1sen!2sus"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>

      <Footer />
    </>
  );
};

export default Contact1;

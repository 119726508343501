import React, { Component } from "react";
import { Link } from "react-router-dom";

// Images
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import about from "../../images/about.png";

class FeatureContent1 extends Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="text-center">
            <h2>ABOUT SMART ENGLISH</h2>
            <p>
              Smart English has the tools to transform your dream of MASTERING
              English into reality. .
            </p>
            <img src={about} alt="vf" className="mb-5" />
          </div>
          <div className="col-lg-5 col-md-6 col-sm-6 m-b30">
            <div className="feature-container">
              <div className="feature-md text-white m-b20">
                <Link to="#" className="icon-cell">
                  <img src={icon1} alt="ss" />
                </Link>
              </div>
              <div className="icon-content">
                <h5 className="ttr-tilte">WHO WE ARE</h5>
                <p>
                  Smart English is a young and vibrant English school in the
                  heart of Melbourne, Australia. <br /> Since our start, we have
                  worked hard to establish our reputation in a solid way by
                  offering our students a personalised learning experience and
                  ongoing academic support. <br /> At Smart English we are
                  passionate about teaching English to international students!
                  That is why we have put together a team of qualified teachers
                  with global experience who are committed to teaching students
                  from all over the world with enthusiasm and dedication!
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-6 col-sm-6 m-b30">
            <div className="feature-container">
              <div className="feature-md text-white m-b20">
                <Link to="#" className="icon-cell">
                  <img src={icon2} alt="ss" />
                </Link>
              </div>
              <div className="icon-content">
                <h5 className="ttr-tilte">WHAT WE OFFER</h5>
                <ul>
                  <li>
                    Personalised study plans to achieve your goals within your
                    time expectations{" "}
                  </li>
                  <li>
                    Qualified teachers with extensive experience in Australia
                    and overseas
                  </li>
                  <li>Multi-language student support staff </li>
                  <li>Wide range of English courses</li>
                  <li>Language Test preparation courses</li>
                  <li>Online English courses for groups and 1:1</li>
                  <li>Personal and academic support </li>
                  <li>Intense and dynamic classes</li>
                  <li>Professionalism and reliability </li>
                  <li>Private classes (on request) </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FeatureContent1;
